import types from "../../action_types";

export const initialState = {
  upcomingDataList: [],
  data: [],
  error: ""
};

export default function(state = initialState, action) {
  const { payload } = action || {};
  switch (action.type) {
    case types.TRAININGS_FETCHED_SUCCESS:
      return {
        ...state,
        data: payload,
        upcomingDataList: payload
      };

    case types.TRAININGS_FETCHED_ERROR:
      return {
        ...state,
        error: payload
      };

    case types.SEARCH_BY_TITLE:
      const { upcomingDataList: titleList } = state || {};
      const titleFilterData = titleList.filter(
        item =>
          item.title.toUpperCase().includes(payload.toUpperCase()) ||
          item.Topic.toUpperCase().includes(payload.toUpperCase()) ||
          // {
          //   console.log(
          //     "sajss",
          //     item.childDetails && item.childDetails.map(item => item.title.toUpperCase().indexOf(payload.toUpperCase())).indexOf(0) >= 0,
          //     item.childDetails
          //   );
          (item.childDetails && item.childDetails.map(item => item.title.toUpperCase().indexOf(payload.toUpperCase())).indexOf(0) >= 0)
        // }
      );
      return {
        ...state,
        data: titleFilterData
      };

    case types.SEARCH_BY_MONTH:
      const { upcomingDataList: monthList } = state || {};
      const monthFilterData = monthList.filter(
        item =>
          item.eventdate.toUpperCase().includes(payload.toUpperCase()) ||
          (item.childDetails &&
            item.childDetails.map(item => item.eventdate.toUpperCase().indexOf(payload.toUpperCase())).find(item => item >= 0) !== undefined)
      );
      return {
        ...state,
        data: monthFilterData
      };

    case types.SEARCH_BY_VENUE:
      const { upcomingDataList: venueList } = state || {};
      const venueFilterData = venueList.filter(
        item =>
          (item.venue && item.venue.toUpperCase().includes(payload.toUpperCase())) ||
          // {
          //   console.log(
          //     "sajss",
          //     item.childDetails &&
          //       item.childDetails.map(item => item.venue.toUpperCase().indexOf(payload.toUpperCase())).find(item => item >= 0) !== undefined,
          //     item.childDetails
          //   );

          (item.childDetails &&
            item.childDetails.map(item => item.venue.toUpperCase().indexOf(payload.toUpperCase())).find(item => item >= 0) !== undefined)
        // }
      );
      return {
        ...state,
        data: venueFilterData
      };

    default:
      return state;
  }
}
