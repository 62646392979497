import React from "react";
import Input from "@material-ui/core/Input";
import config from "../../config";

class PaymentForm extends React.Component {
  render = () => {
    return (
      <form method="post" id="gatewayForm" name="gatewayForm" action={config.epayURL}>
        <Input className="hide" type="hidden" name="MerchantCode" value={config.merchantCode} />
        <Input className="hide" type="hidden" name="PaymentId" value="2" />
        <Input className="hide" type="hidden" name="RefNo" value={"tfufzzi58075"} />
        <Input className="hide" type="hidden" name="Amount" value={"1200"} />
        <Input className="hide" type="hidden" name="Currency" value={config.currency} />
        <Input className="hide" type="hidden" name="ProdDesc" value={"Membership Fees"} />
        <Input className="hide" type="hidden" name="UserName" value={"Aayush Gupta"} />
        <Input className="hide" type="hidden" name="UserEmail" value={"aayushgupta1991@gmail.com"} />
        <Input className="hide" type="hidden" name="UserContact" value={""} />
        <Input className="hide" type="hidden" name="Remark" value={""} />
        <Input className="hide" type="hidden" name="Lang" value={config.epayLang} />
        <Input className="hide" type="hidden" name="SignatureType" value={config.epaySignatureType} />
        <Input className="hide" type="hidden" name="Signature" value={"1d99303ab511bf57a7278dc14ed10ce16f2c6e8974da9e78de9f4be56ae2b3d3"} />
        <Input
          className="hide"
          type="hidden"
          name="ResponseURL"
          value={"https://member.mia.org.my/mia/mia_uat/mia/ipay88response/157195/tfufzzi58075"}
        />
        <Input className="hide" type="hidden" name="BackendURL" value={"https://startroi.com/build/index.html"} />
        <button id="gatewayFormButton" className="hide" type="submit" name="Submit">
          Proceed with Payment
        </button>
      </form>
    );
  };
}

export default PaymentForm;
