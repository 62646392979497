import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Hidden from "@material-ui/core/Hidden";

// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardIcon from "../../components/material-ui/Card/CardIcon.jsx";
import CardFooter from "../../components/material-ui/Card/CardFooter.jsx";
import CustomButtons from "../../components/material-ui/CustomButtons/Button";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { find_in_object } from "../../helpers/globalFunctions";
import { getUpcomingTrainings } from "../../actions";
import SearchInput from "../../components/SearchInput";
import { onSearchByTitle, onSearchByVenue } from "../../actions/upcomingTrainings";
class CPEProg extends React.Component {
  state = {};
  componentDidMount() {
    this.props.getUpcomingTrainings();
  }

  getCPEData = () => {
    const { data } = this.props || {};
    const cpeData = find_in_object(data, { eventpublicpage: "CPE" });
    return cpeData;
  };

  incLike = eventID => {
    this.state.hasOwnProperty(eventID)
      ? this.setState({
          [eventID]: this.state[eventID] + 1
        })
      : this.setState({
          [eventID]: 1
        });
  };

  onSearchTitle = e => {
    this.props.onSearchByTitle(e.target.value);
  };

  onSearchVenue = e => {
    this.props.onSearchByVenue(e.target.value);
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <span style={{ width: "100%" }}>
          <Hidden mdUp implementation="css">
            <div>
              <SearchInput onChange={this.onSearchTitle} label="Search by title" />
            </div>
            <div>
              <SearchInput onChange={this.onSearchVenue} label="Search by venue" />
            </div>
          </Hidden>
        </span>
        <GridContainer>
          {this.getCPEData().length !== 0 ? (
            this.getCPEData().map((item, index) => (
              <GridItem key={index} xs={12} sm={6} md={3}>
                <Card style={{ height: "350px" }}>
                  <Link to={{ pathname: "/register", state: { item } }}>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="warning">
                        <img
                          src={item.s3image}
                          style={{ width: "12em", height: "10em", minWidth: "100%", borderRadius: "3px" }}
                          className="evt_img"
                        />
                      </CardIcon>
                      <p className={`${classes.cardCategory} text--left`}>{item.title}</p>
                      {/* <div className={`${classes.cardCategory} text--left`}>{item.startDate}</div>
                      <div className={`${classes.cardCategory} text--left`}>{item.venue}</div> */}
                    </CardHeader>
                  </Link>
                  {/* <CardFooter stats style={{ textAlign: "center", display: "block", position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <div className={classes.stats}>
                      {item.btnName === "Register" ? (
                        <div>
                          <Link to={{ pathname: "/register", state: { item } }}>
                            <CustomButtons color="blueSecondaryColor">{item.btnName}</CustomButtons>
                          </Link>
                          
                        </div>
                      ) : (
                        <Link to={{ pathname: "/childdetails", state: { item } }}>
                          <CustomButtons color="blueSecondaryColor">{item.btnName}</CustomButtons>
                        </Link>
                      )}
                    </div>
                  </CardFooter> */}
                </Card>
              </GridItem>
            ))
          ) : (
            <div className="pos-abs txt-m left--35 top--50 left--15">
              {/* <span style={{ color: "black" }}>There are no CPE Programme upcoming event.</span> */}
            </div>
          )}
        </GridContainer>
      </div>
    );
  }
}

CPEProg.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUpcomingTrainings,
      onSearchByTitle,
      onSearchByVenue
    },
    dispatch
  );

const mapStateToProps = state => {
  const { upcomingTrainings } = state || {};
  const { data } = upcomingTrainings || {};
  return {
    data
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(CPEProg));
