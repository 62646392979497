import actionTypes from "../../action_types";

export function searchFaq(value) {
  return dispatch => {
    dispatch({
      type: actionTypes.SEARCH_FAQ,
      payload: value
    });
  };
}
