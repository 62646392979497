import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import CustomTabs from "../../components/material-ui/CustomTabs/CustomTabs";
import Event from "@material-ui/icons/Event";
import CPE from "../../components/FAQ/CPE";
import FOC from "../../components/FAQ/FOC";
import Card from "../../components/material-ui/Card/Card";
import CardHeader from "../../components/material-ui/Card/CardHeader";
import CardBody from "../../components/material-ui/Card/CardBody";
class TermsConditions extends React.Component {
  render() {
    return (
      <div>
        <CustomTabs
          headerColor="info"
          tabs={[
            {
              tabName: "TERMS & CONDITIONS FOR CPE PROGRAMMES",
              tabIcon: Event,
              tabContent: <CPE />
            },
            {
              tabName: "TERMS & CONDITIONS FOR COMPLIMENTARY TALKS/EVENTS",
              tabIcon: Event,
              tabContent: <FOC />
            }
          ]}
        />

        <div className="push-top--50">
          <Card>
            <CardHeader color="info">PARTICIPANT’S CLASSIFICATION AND INFORMATION</CardHeader>
            <CardBody>
              <div>Category: Corporate/ Individual</div>
              <ul>
                <li>
                  Please select the participant classification carefully as it determines the fee payable. No alteration will be allowed upon
                  registration.{" "}
                </li>
                <li>
                  The information on Corporate/Individual provided shall be deemed true and correct. No alteration will be allowed upon registration.{" "}
                </li>
              </ul>
            </CardBody>
          </Card>
        </div>

        <div className="push-top--50">
          <Card>
            <CardHeader color="info">VERIFICATION OF ATTENDANCE</CardHeader>
            <CardBody>
              All participants are required to present photo identification (NRIC, driving license or company’s ID card) at the point of registration
              prior to signing the registration list when attending MIA CPE programme. Admittance may be denied upon failure to present photo
              identification.
            </CardBody>
          </Card>
        </div>

        <div className="push-top--50">
          <Card>
            <CardHeader color="info">CERTIFICATE OF ATTENDANCE AND CPE HOURS</CardHeader>
            <CardBody>
              <ul>
                <li>
                  Upon full attendance of the programme, participants will be issued an “e-certificate”. For this purpose, it is COMPULSORY to fill in
                  the email address clearly.
                </li>
                <li>For MIA members, the CPE hours will be credited into the Membership System within 2 weeks of the event.</li>
                <li>
                  Participants will only be entitled to the CPE hours upon attending the entire duration of the programme.{" "}
                  <b>CPE hours will not be accorded for partial attendance</b>.
                </li>
              </ul>
            </CardBody>
          </Card>
        </div>

        <div className="push-top--50">
          <Card>
            <CardHeader color="info">DATA PROTECTION</CardHeader>
            <CardBody>Personal Data is gathered in accordance with the Personal Data Protection Act 2010 (Act 709).</CardBody>
          </Card>
        </div>

        <div className="push-top--50">
          <Card>
            <CardHeader color="info">DISCLAIMER</CardHeader>
            <CardBody>
              Malaysian Institute of Accountants (MIA) reserves the right to change the speaker(s), date(s) and to cancel the programme should
              circumstances beyond its control arise. MIA shall not be responsible for any costs, damages or losses incurred by the participant due to
              the changes and/or cancellation. MIA also reserves the right to make alternative arrangements without prior notice should it be
              necessary to do so. Upon registering, you are deemed to have read and accepted the terms and conditions herein.
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

TermsConditions.propTypes = {
  classes: PropTypes.object.isRequired
};

export default TermsConditions;
