import React, { PureComponent } from "react";
import DateFnsUtils from "material-ui-pickers/utils/date-fns-utils";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import DatePicker from "material-ui-pickers/DatePicker";

class Dpicker extends PureComponent {
  render() {
    const { selectedDate, handleDateChange, label } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          label={label}
          value={selectedDate}
          format="dd/MM/yyyy"
          placeholder="10/10/2018"
          onChange={handleDateChange}
          style={{ margin: "37px 0 0" }}
          fullWidth
          disableFuture
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default Dpicker;
