import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import selectInputStyle from "../../../assets/jss/material-dashboard-react/components/selectInputStyle.jsx";

class SelectInput extends React.Component {
  state = {
    age: ""
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes, data, label, value, onChange } = this.props;
    return (
      <form className={classes.root} autoComplete="off">
        <FormControl className={classes.formControl}>
          <InputLabel style={{ fontSize: "14px" }} htmlFor="age-simple">
            {label}
          </InputLabel>
          <Select
            value={value}
            onChange={onChange}
            inputProps={{
              id: { label }
            }}
            style={{ fontSize: "14px", margin: "25px 0 0 0" }}>
            {data.map((item, index) => (
              <MenuItem key={index} value={item.codCode} style={{ fontSize: "14px" }}>
                {item.codName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

SelectInput.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(selectInputStyle)(SelectInput);
