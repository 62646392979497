import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactHtmlParser from "react-html-parser";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import CustomInput from "../../components/material-ui/CustomInput/CustomInput.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardBody from "../../components/material-ui/Card/CardBody.jsx";
import SelectInput from "../../components/material-ui/SelectInput";
import { getStateData, getCountryData } from "../../helpers/globalLookUpData";
import {
  onAdd1Change,
  onAdd2Change,
  onAdd3Change,
  onCityChange,
  onStateChange,
  onCountryChange,
  onPostCodeChange
} from "../../actions/registrationActions";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "20px"
  }
};

class AddressDetails extends Component {
  render() {
    const { classes, registration } = this.props;
    const { address1, address2, address3, city, state, postcode, country } = registration || {};
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>Address Details</div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Address1<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onAdd1Change(e.target.value),
                      value: address1
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Address2"
                    id="Address2"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onAdd2Change(e.target.value),
                      value: address2
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Address3"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onAdd3Change(e.target.value),
                      value: address3
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>City<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="city"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onCityChange(e.target.value),
                      value: city
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <SelectInput
                    label={ReactHtmlParser(`<span>State<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    data={getStateData()}
                    onChange={e => this.props.onStateChange(e.target.value)}
                    value={state}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Postcode<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="postcode"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onPostCodeChange(e.target.value),
                      value: postcode
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <SelectInput
                    label={ReactHtmlParser(`<span>Country<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    data={getCountryData()}
                    onChange={e => this.props.onCountryChange(e.target.value)}
                    value={country}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  const { registration } = state || {};
  return {
    registration
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onAdd1Change,
      onAdd2Change,
      onAdd3Change,
      onCityChange,
      onCountryChange,
      onStateChange,
      onPostCodeChange
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddressDetails));
