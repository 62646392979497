import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

class CustomInputAdornment extends React.Component {

  render() {
    const { classes, label, value } = this.props;

    return (
      <div>
        <TextField
          id="outlined-name"
          label={label}
          className={classes.textField}
          value={value}
          disabled
          margin="normal"
          variant="outlined"
        />
      </div>
    );
  }
}

CustomInputAdornment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomInputAdornment);
