import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "../material-ui/CustomInput/CustomInput.jsx";
import Button from "../material-ui/CustomButtons/Button";

import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

class SearchInput extends React.Component {
  render() {
    const { classes, label, onChange } = this.props;
    return (
      <div>
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search
            }}
            inputProps={{
              placeholder: label,
              inputProps: {
                "aria-label": "Search"
              },
              style: { color: "#fff" },
              onChange
            }}
          />
          <Button color="white" aria-label="edit" justIcon round>
            <Search />
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(SearchInput);
