import {
    primaryColor,
    dangerColor,
    successColor,
    defaultFont
  } from "../../material-dashboard-react.jsx";
  
  const selectInputStyle = {
    formControl: {
      paddingBottom: "10px",
      margin: "30px 0 0 0",
      position: "relative",
      width: '100%'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    font:{
        fontSize: '14px'
    }
  };
  
  export default selectInputStyle;
  