import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactHtmlParser from "react-html-parser";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import CustomInput from "../../components/material-ui/CustomInput/CustomInput.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardBody from "../../components/material-ui/Card/CardBody.jsx";
import SelectInput from "../../components/material-ui/SelectInput";
import { getSalutationData, getNationality } from "../../helpers/globalLookUpData";
import Dpicker from "../../components/Common/DatePicker";
import {
  onSalutationChange,
  onNameChange,
  onNationalityChange,
  onDobChange,
  onMobileNoChange,
  onCompanyChange,
  onEmailChange,
  onDesignationChange
} from "../../actions/registrationActions";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "20px"
  }
};

class PersonalDetaisl extends Component {
  state = {
    selectedDate: null,
    mobile: ""
  };

  handleDateChange = date => {
    const formatedDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    this.setState({ selectedDate: date });
    this.props.onDobChange(formatedDate);
  };

  onMobileChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value == "" || re.test(e.target.value)) {
      this.setState({ mobile: e.target.value });
      this.props.onMobileNoChange(e.target.value);
    }
  };

  render() {
    const { classes, registration } = this.props || {};
    const { salutation, name, nationality, dob, mobile, email, companyname, jobtitle, validEmail, validContact } = registration || {};
    const { selectedDate } = this.state || {};
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>Personal Details</div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <SelectInput
                    label={ReactHtmlParser(`<span>Salutation<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    data={getSalutationData()}
                    onChange={e => this.props.onSalutationChange(e.target.value)}
                    value={salutation}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Full Name (as per IC/Passport)<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onNameChange(e.target.value),
                      value: name
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <SelectInput
                    label={ReactHtmlParser(`<span>Nationality<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    data={getNationality()}
                    onChange={e => this.props.onNationalityChange(e.target.value)}
                    value={nationality}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <Dpicker
                    label={ReactHtmlParser(`<span>Date Of Birth<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    selectedDate={selectedDate}
                    handleDateChange={this.handleDateChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Mobile Number<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="Mobile no"
                    error={validContact !== "" && !validContact}
                    errorText="Please enter the valid mobile number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.onMobileChange(e),
                      value: this.state.mobile
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Email<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="email"
                    errorText="Please enter the valid email address"
                    error={validEmail !== "" && !validEmail}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onEmailChange(e.target.value),
                      value: email
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Company Name"
                    id="company"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onCompanyChange(e.target.value),
                      value: companyname
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Job Title"
                    id="Title"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onDesignationChange(e.target.value),
                      value: jobtitle
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  const { registration } = state || {};
  return {
    registration
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSalutationChange,
      onNameChange,
      onNationalityChange,
      onDobChange,
      onMobileNoChange,
      onCompanyChange,
      onEmailChange,
      onDesignationChange
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PersonalDetaisl));
