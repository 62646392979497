import React from "react";
import PropTypes from "prop-types";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import ViewFlyer from "../ViewFlyer";
import Register from "../Register";
import VerifyAndPay from "../VerifyAndPay";
import CustomButton from "../../components/material-ui/CustomButtons/Button";

const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing.unit
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  }
});

function getSteps() {
  return ["View Flyer", "Register", "Verify & Make Payment"];
}

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return <ViewFlyer evt_id={this.props.evt_id} docType={this.props.docType} />;
      case 1:
        return <Register handleNext={this.handleNext} />;
      case 2:
        return <VerifyAndPay />;
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep + 1
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>
                  <span className="txt">{label}</span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          <div className="text--right">
            {activeStep === 0 ? (
              <div />
            ) : (
              <Button onClick={this.handleBack} className={classes.button}>
                <KeyboardArrowLeft style={{ color: "black" }} />
                <span className="pos-rel" style={{ color: "black" }}>
                  Back
                </span>
              </Button>
            )}

            {activeStep === 1 || activeStep === 2 ? (
              <div />
            ) : (
              <CustomButton size={"md"} variant="contained" color="blueSecondaryColor" onClick={this.handleNext} className={classes.button}>
                <span className="pos-rel">Register</span>
                <KeyboardArrowRight style={{ color: "#fff" }} />
              </CustomButton>
            )}
          </div>
          <div className={classes.instructions}>{this.getStepContent(activeStep)}</div>
        </div>
      </div>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(HorizontalLinearStepper);
