import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "../material-ui/CustomButtons/Button";
import { doRegistration } from "../../actions/registrationActions";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardBody from "../../components/material-ui/Card/CardBody.jsx";
import CustomInputAdornment from "../../components/material-ui/CustomInputAdornment";
import PaymentForm from "../../components/PaymentForm";
import { getSalutationData, getNationality, getCountryData, getStateData, getMealPrefData } from "../../helpers/globalLookUpData";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "20px"
  }
};

class VerifyAndPay extends Component {
  makePayment = () => {
    const gatewayFormEle = document.getElementById("gatewayFormButton");
    gatewayFormEle && gatewayFormEle.click();
  };

  render() {
    const { registration, classes } = this.props || {};
    const {
      salutation,
      name,
      nationality,
      dob,
      mobile,
      email,
      companyname,
      jobtitle,
      address1,
      address2,
      address3,
      city,
      state,
      postcode,
      country,
      meal,
      fees,
      bankname,
      accountno,
      accountholder
    } = registration || {};
    const salutationValue = getSalutationData().filter(item => item.codCode === salutation);
    const nationalityValue = getNationality().filter(item => item.codCode === nationality);
    const countryValue = getCountryData().filter(item => item.codCode === country);
    const stateValue = getStateData().filter(item => item.codCode === state);
    const mealValue = getMealPrefData().filter(item => item.codCode === meal);
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <div className={classes.cardTitleWhite}>Verify your details and proceed to payment</div>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <div style={{ fontSize: "20px", padding: "25px" }}>Personal Details</div>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Salutation" value={(salutationValue.length > 0 && salutationValue[0].codName) || ""} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Name" value={name} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Nationality" value={(nationalityValue.length > 0 && nationalityValue[0].codName) || ""} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Date of Birth" value={dob} />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Mobile No" value={mobile} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Email" value={email} />
                  </GridItem>
                  {companyname && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInputAdornment label="Company Name" value={companyname} />
                    </GridItem>
                  )}
                  {jobtitle && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInputAdornment label="Job Title" value={jobtitle} />
                    </GridItem>
                  )}
                </GridContainer>

                <GridContainer>
                  <div style={{ fontSize: "20px", padding: "25px" }}>Address Details</div>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Address 1" value={address1} />
                  </GridItem>
                  {address2 && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInputAdornment label="Address 2" value={address2} />
                    </GridItem>
                  )}
                  {address3 && (
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInputAdornment label="Address 3" value={address3} />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="City" value={city} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="State" value={(stateValue.length > 0 && stateValue[0].codName) || ""} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Country" value={(countryValue.length > 0 && countryValue[0].codName) || ""} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <div style={{ fontSize: "20px", padding: "25px" }}>Register Details</div>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Meal Preference" value={(mealValue.length > 0 && mealValue[0].codName) || ""} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Fees" value={fees} />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <div style={{ fontSize: "20px", padding: "25px" }}>Refund Details</div>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Bank Name" value={bankname} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Account Holder" value={accountholder} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInputAdornment label="Account Number" value={accountno} />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div className="text--right">
          <Button size={"lg"} color="blueSecondaryColor" onClick={this.makePayment} className="push--left">
            Make Payment
          </Button>
        </div>
        <PaymentForm />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doRegistration
    },
    dispatch
  );

const mapStateToProps = state => {
  const { registration } = state || {};
  return {
    registration
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VerifyAndPay));
