import React, { Component } from "react";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { HorizontalLinearStepper } from "../../components/Common";
import ViewFlyer from "../../components/ViewFlyer";
import CustomButton from "../../components/material-ui/CustomButtons/Button";

class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { location, history } = this.props || {};
    const { state } = location || {};
    const { item } = state || {};
    const { id, docType, eventpublicpage } = item || {};
    return (
      <div>
        <div>
          <span>
            <a onClick={() => history.goBack()} target="_blank">
              <CustomButton variant="contained" color="blueSecondaryColor">
                <KeyboardArrowLeft style={{ color: "#fff" }} />
                <span className="pos-rel">Back</span>
              </CustomButton>
            </a>
          </span>
          {eventpublicpage === "CPE" ? (
            <div />
          ) : (
            <span className="float--right">
              <a href="https://member.mia.org.my/#/login" target="_blank">
                <CustomButton variant="contained" color="blueSecondaryColor">
                  <span className="pos-rel">Register</span>
                  <KeyboardArrowRight style={{ color: "#fff" }} />
                </CustomButton>
              </a>
            </span>
          )}
        </div>
        )}
        <ViewFlyer evt_id={id} docType={docType} />
        {/* <HorizontalLinearStepper evt_id={id} docType={docType} /> */}
      </div>
    );
  }
}

export default RegisterForm;
