import registration from "./registration";
import upcomingTrainings from "./upcomingTrainings";
import codeViews from "./codeViews";
import searchFaq from "./searchFaq";

export default {
  registration,
  upcomingTrainings,
  codeViews,
  searchFaq
};
