import React, { Component } from "react";
import { Document, Page } from "react-pdf/dist/entry.noworker";
import withStyles from "@material-ui/core/styles/withStyles";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Hidden from "@material-ui/core/Hidden";

// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import { Loader } from "../Common";
import config from "../../config";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "20px"
  }
};

class ViewFlyer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      pageNumber: 1
    };
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages });
  };

  renderLoader = () => <Loader />;

  clickNext = () => {
    const { pageNumber, numPages } = this.state || {};
    console.log("kdds", pageNumber, numPages);
    if (pageNumber < numPages) {
      this.setState({ pageNumber: this.state.pageNumber + 1 });
    }
  };

  clickPrevious = () => {
    if (this.state.pageNumber > 1) {
      this.setState({ pageNumber: this.state.pageNumber - 1 });
    }
  };

  render() {
    const { pageNumber, numPages } = this.state || {};
    const { classes, evt_id, docType, type } = this.props || {};
    const url = `${config.api_base_url}/mia/flyer/${evt_id}/Flyer`;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {docType === "PDF" ? (
              <div>
                <CardHeader color="info">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={classes.cardTitleWhite}>
                        <span>
                          {this.state.pageNumber !== 1 ? (
                            <span className="lnk" onClick={this.clickPrevious}>
                              <KeyboardArrowLeft />{" "}
                              <span className="pos-rel" style={{ top: "-6px" }}>
                                Prev
                              </span>
                            </span>
                          ) : (
                            <div />
                          )}
                          {this.state.pageNumber === this.state.numPages ? (
                            <div />
                          ) : (
                            <span className="float--right lnk show-small-only" onClick={this.clickNext}>
                              <span className="pos-rel" style={{ top: "-6px" }}>
                                {" "}
                                Next
                              </span>
                              <KeyboardArrowRight />
                            </span>
                          )}
                        </span>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={`${classes.cardTitleWhite} text--center`}>
                        Page {pageNumber} / {numPages}
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <div className={`${classes.cardTitleWhite} text--right lnk`}>
                        <a
                          href={type === "inhouse" ? "assets/InHouse.pdf" : url}
                          target="_blank"
                          rel="noopener"
                          className="push--right lnk text--white">
                          <CloudDownload />
                        </a>
                        {this.state.pageNumber === this.state.numPages ? (
                          <div />
                        ) : (
                          <span className="show-medium-up lnk" onClick={this.clickNext}>
                            <span className="pos-rel" style={{ top: "-6px" }}>
                              {" "}
                              Next
                            </span>
                            <KeyboardArrowRight />
                          </span>
                        )}
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <Document file={type === "inhouse" ? "assets/InHouse.pdf" : url} onLoadSuccess={this.onDocumentLoad} loading={this.renderLoader()}>
                  <a href={type === "inhouse" && "mailto:inhousetraining@mia.org.my"}>
                    <Page pageNumber={pageNumber} />
                  </a>
                </Document>
              </div>
            ) : (
              <div>
                <CardHeader color="info">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <div>
                        <span className={`${classes.cardTitleWhite}`}>Page 1/1</span>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div className={`${classes.cardTitleWhite} text--right lnk`}>
                        <a href={url} target="_blank" rel="noopener" className="push--right lnk text--white">
                          <CloudDownload />
                        </a>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <div className="push--top">
                  <img style={{ maxWidth: "100%" }} alt="flyerPng" src={url} />
                </div>
              </div>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ViewFlyer);
