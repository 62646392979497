import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardIcon from "../../components/material-ui/Card/CardIcon.jsx";
import CardFooter from "../../components/material-ui/Card/CardFooter.jsx";
import CustomButtons from "../../components/material-ui/CustomButtons/Button";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import SearchInput from "../../components/SearchInput";
import data from "../../data";
import { find_in_object } from "../../helpers/globalFunctions";
import { getUpcomingTrainings } from "../../actions";

class MiaConf extends React.Component {
  componentDidMount() {
    this.props.getUpcomingTrainings();
  }

  getMiaConfData = () => {
    const { data } = this.props || {};
    const miaConfData = find_in_object(data, { eventpublicpage: "CONF" });
    return miaConfData;
  };
  render() {
    const { classes } = this.props;
    // console.log("data", this.getMiaConfData());
    return (
      <div>
        <GridContainer>
          {this.getMiaConfData().length !== 0 ? (
            this.getMiaConfData().map((item, index) => (
              <GridItem key={index} xs={12} sm={6} md={3}>
                <Card style={{ height: "450px" }}>
                  <CardHeader color="warning" stats icon>
                    <CardIcon color="warning">
                      <img src={item.s3image} style={{ width: "12em", height: "10em", minWidth: "100%", borderRadius: "3px" }} className="evt_img" />
                    </CardIcon>
                    <p className={`${classes.cardCategory} text--left`}>{item.title}</p>
                    <div className={`${classes.cardCategory} text--left`}>{item.startDate && `${item.startDate} - ${item.endDate}`}</div>
                    <div style={{ color: "#999999" }} className={`text--left`}>
                      {item.venue}
                    </div>
                  </CardHeader>
                  <CardFooter stats style={{ textAlign: "center", display: "block", position: "absolute", bottom: 0, left: 0, right: 0 }}>
                    <div className={classes.stats}>
                      {item.eventType === "MIACO" ? (
                        <a href="http://miaconference.mia.org.my/" target="_blank">
                          <CustomButtons color="blueSecondaryColor">{"Show More"}</CustomButtons>
                        </a>
                      ) : (
                        <div>
                          {item.btnName === "Register" ? (
                            <div>
                              <Link to={{ pathname: "/register", state: { item } }}>
                                <CustomButtons color="blueSecondaryColor">{item.btnName}</CustomButtons>
                              </Link>
                              {/* <CustomButtons>
                        <ThumbUp style={{height: '20px', width: '20px', top: 0}} />
                      </CustomButtons> */}
                            </div>
                          ) : (
                            <Link to={{ pathname: "/childdetails", state: { item } }}>
                              <CustomButtons color="blueSecondaryColor">{item.btnName}</CustomButtons>
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </GridItem>
            ))
          ) : (
            <div className="pos-abs txt-m left--35 top--50 left--15">
              {/* <span style={{ color: "black" }}>There are no MIA Conference events at the moment. Watch this space!</span> */}
            </div>
          )}
        </GridContainer>
      </div>
    );
  }
}

MiaConf.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUpcomingTrainings
    },
    dispatch
  );

const mapStateToProps = state => {
  const { upcomingTrainings } = state || {};
  const { data } = upcomingTrainings || {};
  return {
    data
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(dashboardStyle)(MiaConf));
