import axios from "axios";
import config from "../../config";
import actionTypes from "../../action_types";
import constants from "../../constants";

export function getUpcomingTrainings() {
  return dispatch => {
    axios
      .get(`${config.api_base_url}/pdpublic/events`)
      .then(response => {
        const { data } = response || {};
        dispatch({
          type: actionTypes.TRAININGS_FETCHED_SUCCESS,
          payload: data
        });
      })
      .catch(err => {
        console.log("error in action", err);
        dispatch({
          type: actionTypes.TRAININGS_FETCHED_ERROR,
          payload: err
        });
      });
  };
}

export function onSearchByTitle(value) {
  return dispatch => {
    dispatch({
      type: actionTypes.SEARCH_BY_TITLE,
      payload: value
    });
  };
}

export function onSearchByVenue(value) {
  return dispatch => {
    dispatch({
      type: actionTypes.SEARCH_BY_VENUE,
      payload: value
    });
  };
}

export function onSearchByMonth(value) {
  return dispatch => {
    dispatch({
      type: actionTypes.SEARCH_BY_MONTH,
      payload: value
    });
  };
}
