import axios from 'axios';
import config from '../../config';
import actionTypes from '../../action_types';

export function getCodeViews() {
    return  (dispatch) => {
        axios.get(`${config.api_base_url}/mia/codes_view_pub/`)
            .then(response => {
                const{data} = response || {};
                localStorage.setItem('CODEVIEWS', JSON.stringify(data));
                dispatch({
                    type: actionTypes.CODE_VIEW_FETCHED_SUCCESS,
                    payload: data
                })
            }).catch(err => {
                console.log("error in action", err);
                dispatch({
                    type: actionTypes.CODE_VIEW_FETCHED_ERROR,
                    payload: err
                })
            })
    }
}