import types from '../../action_types';

export const initialState = {
    data: [],
    error: ''
};

export default function (state = initialState, action) {
    const{payload} = action || {};
    switch (action.type) {
        case types.CODE_VIEW_FETCHED_SUCCESS:
            return {
                ...state,
                data: payload,
            };

        case types.CODE_VIEW_FETCHED_ERROR:
            return {
                ...state,
                error: payload,
            };

        default:
            return state;
        }
    }
