// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import FindInPage from "@material-ui/icons/FindInPage";
import Event from "@material-ui/icons/Event";
import Store from "@material-ui/icons/Store";
import Public from "@material-ui/icons/Public";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Contacts from "@material-ui/icons/Contacts";
import DashboardPage from "../pages/Dashboard/Dashboard.jsx";
import RegisterForm from "../pages/RegisterForm/RegisterForm.jsx";
import CPEProg from "../pages/CPEProg";
import MiaConf from "../pages/MiaConf";
import FAQs from "../pages/FAQs";
import TermsConditions from "../pages/TermsConditions";
import ChildDetails from "../pages/ChildDetails";
import ContactUs from "../pages/Contact";
import InHouse from "../pages/InHouse";

const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "CPE Training & e-Learning",
    navbarName: "CPE Training & e-Learning",
    icon: Event,
    component: DashboardPage
  },
  {
    path: "/register",
    invisible: true,
    sidebarName: "Registration",
    navbarName: "Registration",
    icon: Dashboard,
    component: RegisterForm
  },
  {
    path: "/miaconf",
    sidebarName: "Conferences",
    navbarName: "Conferences",
    icon: Public,
    component: MiaConf
  },
  {
    path: "/cpeprog",
    sidebarName: "Download CPE series",
    navbarName: "Download CPE series",
    icon: LibraryBooks,
    component: CPEProg
  },
  {
    path: "/inhouse",
    sidebarName: "In House Training Solutions",
    navbarName: "In House Training Solutions",
    icon: Store,
    component: InHouse
  },
  {
    path: "/childdetails",
    invisible: true,
    sidebarName: "More Events",
    navbarName: "More Events",
    icon: Dashboard,
    component: ChildDetails
  },
  {
    path: "/faqs",
    sidebarName: "FAQs",
    navbarName: "FAQs",
    icon: HelpOutline,
    component: FAQs
  },
  {
    path: "/tnc",
    sidebarName: "Terms & Conditions",
    navbarName: "Terms & Conditions",
    icon: FindInPage,
    component: TermsConditions
  },
  {
    path: "/contact",
    sidebarName: "Contact Us",
    navbarName: "Contact Us",
    icon: Contacts,
    component: ContactUs
  },
  { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
];

export default dashboardRoutes;
