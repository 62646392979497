export const isValidEmail = email => new RegExp(/^([a-z0-9+_-]+)(\.[a-z0-9+_-]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/gi).test(email);

export const isValidContact = number => number.length > 6 && number.length < 13;

export const validateLength = input => {
  if (input.length === 0) {
    return false;
  }
  return true;
};
