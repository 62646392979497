import {find_in_object, compare} from "./globalFunctions";

const codeViewData = localStorage.getItem('CODEVIEWS');

export const getSalutationData = () => {
    const salutationData = find_in_object(JSON.parse(codeViewData), { cotCode: 'TITLE' });
    salutationData && salutationData.sort(compare);
    return salutationData
};

export const getNationality = () => {
    const nationalityData = find_in_object(JSON.parse(codeViewData), {cotCode: 'MEM_NATIONALITY'});
    nationalityData && nationalityData.sort(compare);
    return nationalityData;
}

export const getCountryData = () => {
    const countryData = find_in_object(JSON.parse(codeViewData), {cotCode: 'COUNTRY'});
    countryData && countryData.sort(compare);
    return countryData;
}

export const getStateData = () => {
    const stateData = find_in_object(JSON.parse(codeViewData), {cotCode: 'STATES_APP'});
    stateData && stateData.sort(compare);
    return stateData;
}

export const getMealPrefData = () => {
    const mealData = find_in_object(JSON.parse(codeViewData), {cotCode: 'MEAL_PREFERENCE'});
    mealData && mealData.sort(compare);
    return mealData;
}