import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import Button from "../CustomButtons/Button.jsx";
import SearchInput from "../../SearchInput";

import headerStyle from "../../../assets/jss/material-dashboard-react/components/headerStyle.jsx";
import { onSearchByTitle, onSearchByVenue, onSearchByMonth } from "../../../actions/upcomingTrainings";
import { searchFaq } from "../../../actions/searchFaq";
import Picker from "../../Common/Picker";
class Header extends React.Component {
  // Function to display the Navbar name in page header which we are passing from dashboard routes
  makeBrand = () => {
    var name;
    this.props.routes.map((prop, key) => {
      if (prop.path === this.props.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });
    return name;
  };

  onSearchTitle = e => {
    this.props.onSearchByTitle(e.target.value);
  };

  onSearchVenue = e => {
    this.props.onSearchByVenue(e.target.value);
  };

  onSearchMonth = e => this.props.onSearchByMonth(e.target.value);

  onSearchFaq = e => this.props.searchFaq(e.target.value);

  render() {
    const { classes, color, pathName } = this.props;
    const appBarClasses = classNames({
      // className is a module used for conditionally joining classNames
      [" " + classes[color]]: color
    });
    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button color="transparent" href="#" className={classes.title}>
              {this.makeBrand()}
            </Button>
          </div>
          {pathName === "/register" || pathName === "/tnc" || pathName === "/childdetails" || pathName === "/contact" ? (
            <div />
          ) : pathName === "/faqs" ? (
            <Hidden smDown implementation="css">
              <SearchInput onChange={this.onSearchFaq} label="Search FAQs" />
            </Hidden>
          ) : (
            <Hidden smDown implementation="css">
              <div className="text--right hl">
                <div className="hli">
                  <SearchInput onChange={this.onSearchTitle} label="Search by title or topic" />
                </div>
                <div className="hli">
                  <SearchInput onChange={this.onSearchVenue} label="Search by location" />
                </div>
                <div className="hli">
                  <SearchInput onChange={this.onSearchMonth} label="Search by month" />
                </div>
              </div>
            </Hidden>
          )}
          <Hidden mdUp implementation="css">
            <IconButton color="inherit" aria-label="open drawer" onClick={this.props.handleDrawerToggle}>
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onSearchByTitle,
      onSearchByVenue,
      onSearchByMonth,
      searchFaq
    },
    dispatch
  );

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(headerStyle)(Header));
