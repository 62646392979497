import React from "react";
import { Toolbar, ToolbarTitle } from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
// import { globalstyles } from "../global/globalstyle";
import Map from "../../components/Map";
import { GoogleApiWrapper } from "google-maps-react";
import Footer from "../../components/Footer";

const styles = {
  headertext: {
    textAlign: "-webkit-center",
    fontWeight: "bold",
    color: "rgb(33, 150, 243)"
  },
  miniheadertext: {
    fontWeight: "bold"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "20px"
  }
};
class ContactUs extends React.Component {
  render = props => {
    const { classes } = this.props || {};
    return (
      <div>
        <Card>
          {/* <CardHeader color="info">
            <div className={classes.cardTitleWhite}>Contact US</div>
          </CardHeader> */}
          <GridContainer>
            <GridItem md={4}>
              <GridContainer>
                <div style={{ margin: "50px 50px 0 50px" }}>
                  <h3 className="text-black">Head Office</h3>
                  <div className="text-black">Dewan Akauntan</div>
                  <div className="text-black">Unit 33-01, Level 33, Tower A</div>
                  <div className="text-black">The Vertical, Avenue 3</div>
                  <div className="text-black">Bangsar South City</div>
                  <div className="text-black">No.8 Jalan Kerinchi</div>
                  <div className="text-black">59200 Kuala Lumpur</div>
                  <div className="text-black">Malaysia</div>
                  <div className="text-black">Tel: 603-2722 9000</div>
                  <div className="text-black">Fax: 603-2722 9100</div>
                  <div className="text-black">
                    e-mail:{" "}
                    <span>
                      <a href="mailto:pd@mia.org.my">pd@mia.org.my</a>
                    </span>
                    <div style={{ marginLeft: "53px" }}>
                      <a href="mailto:mia@mia.org.my">mia@mia.org.my</a>
                    </div>
                  </div>
                </div>
              </GridContainer>
              <GridContainer>
                <div style={{ margin: "50px 50px 0 50px" }}>
                  <h3 className="text-black">MIA Johor Regional Office</h3>
                  <div className="text-black">5.03A, 5th Floor Menara TJB</div>
                  <div className="text-black">No.9, Jalan Syed Mohd Mufti</div>
                  <div className="text-black">80000 Johor Bahru</div>
                  <div className="text-black">Malaysia</div>
                  <div className="text-black">Tel: 607-227 0369</div>
                  <div className="text-black">Fax: 607-222 0391</div>
                  <div className="text-black">
                    e-mail:{" "}
                    <span>
                      <a href="mailto:miajbu@mia.org.my">miajbu@mia.org.my</a>
                    </span>
                  </div>
                </div>
              </GridContainer>

              <GridContainer>
                <div style={{ margin: "50px 50px 0 50px" }}>
                  <h3 className="text-black">MIA Northern Regional Office</h3>
                  <div className="text-black">Suite 3.4 Level 3</div>
                  <div className="text-black">Wisma Great Eastern</div>
                  <div className="text-black">25 Lebuh Light</div>
                  <div className="text-black">10200 Penang</div>
                  <div className="text-black">Malaysia</div>
                  <div className="text-black">Tel: 604-261 3320</div>
                  <div className="text-black">Fax: 604-261 3321</div>
                  <div className="text-black">
                    e-mail:{" "}
                    <span>
                      <a href="mailto:miapng@mia.org.my">miapng@mia.org.my</a>
                    </span>
                  </div>
                </div>
              </GridContainer>

              <GridContainer>
                <div style={{ margin: "50px 50px 0 50px" }}>
                  <h3 className="text-black">MIA Sabah Regional Office</h3>
                  <div className="text-black">Lot 6, Second Floor, Block A</div>
                  <div className="text-black">Damai Point Commercial Centre</div>
                  <div className="text-black">Off Jalan Damai, Luyang</div>
                  <div className="text-black">88300 Kota Kinabalu, Sabah</div>
                  <div className="text-black">Malaysia</div>
                  <div className="text-black">Tel: 6088 - 261 291</div>
                  <div className="text-black">Fax: 6088 - 261 290</div>
                  <div className="text-black">
                    e-mail:{" "}
                    <span>
                      <a href="mailto:miakku@mia.org.my">miakku@mia.org.my</a>
                    </span>
                  </div>
                </div>
              </GridContainer>

              <GridContainer>
                <div style={{ margin: "50px 50px 50px 50px" }}>
                  <h3 className="text-black">MIA Sarawak Regional Office</h3>
                  <div className="text-black">1st Floor Ultimate Professional Centre</div>
                  <div className="text-black">16 Jalan Bukit Mata Kuching</div>
                  <div className="text-black">93100 Kuching, Sarawak </div>
                  <div className="text-black">Malaysia</div>
                  <div className="text-black">Tel: 6082-418 427</div>
                  <div className="text-black">Fax: 6082-417 427</div>
                  <div className="text-black">
                    e-mail:{" "}
                    <span>
                      <a href="mailto:miakch@mia.org.my">miakch@mia.org.my</a>
                    </span>
                  </div>
                </div>
              </GridContainer>
            </GridItem>

            <GridItem style={{ marginTop: "74px" }} md={8} xs={12}>
              <Map google={this.props.google} />
            </GridItem>
          </GridContainer>
        </Card>

        <Footer />
      </div>
    );
  };
}

// export default ContactUs;
export default GoogleApiWrapper({
  apiKey: "AIzaSyAgsYSIvFDCFaTjoac8RLe9hEUob8n1srs"
})(withStyles(styles)(ContactUs));
