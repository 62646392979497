import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PersonalDetails from "./PersonalDetails";
import AddressDetails from "./AddressDetails";
import RegisterDetails from "./RegisterDetails";
import RefundDetails from "./RefundDetails";
import Button from "../material-ui/CustomButtons/Button";
import { doRegistration } from "../../actions/registrationActions";
import { validateLength } from "../../helpers/formValidation";
class Register extends Component {
  submitData = () => {
    const { registration, doRegistration } = this.props || {};
    doRegistration(registration);
  };
  render() {
    const { handleNext, registration } = this.props || {};
    const {
      salutation,
      name,
      nationality,
      dob,
      mobile,
      email,
      companyname,
      jobtitle,
      address1,
      address2,
      city,
      state,
      postcode,
      country,
      meal,
      fees,
      bankname,
      accountno,
      accountholder
    } = registration || {};
    return (
      <div>
        <PersonalDetails />
        <AddressDetails />
        <RegisterDetails />
        <RefundDetails />
        <div className="text--right">
          <Button
            size={"lg"}
            color="blueSecondaryColor"
            onClick={() => handleNext()}
            disabled={
              !validateLength(salutation) ||
              !validateLength(name) ||
              !validateLength(nationality) ||
              !validateLength(dob) ||
              !validateLength(mobile) ||
              !validateLength(email) ||
              !validateLength(address1) ||
              !validateLength(city) ||
              !validateLength(state) ||
              !validateLength(postcode) ||
              !validateLength(country) ||
              !validateLength(meal) ||
              !validateLength(bankname) ||
              !validateLength(accountno) ||
              !validateLength(accountholder)
            }>
            Register
          </Button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doRegistration
    },
    dispatch
  );

const mapStateToProps = state => {
  const { registration } = state || {};
  return {
    registration
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
