import React from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from "react-share";

const Footer = props => {
  const FB = "https://www.facebook.com/miamainpage/";
  const Twitter = "https://twitter.com/miaconnect1967";
  const Linkedin = "https://www.linkedin.com/";
  const urls = [FB, Twitter, Linkedin];
  return (
    <div className="cntr cntr__itm">
      <table>
        <tbody>
          <tr>
            <td>
              <FacebookShareButton style={{ cursor: "pointer" }} url={"https://www.facebook.com/miamainpage/"} quote={""}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </td>

            <td>
              <TwitterShareButton style={{ cursor: "pointer" }} url={"https://twitter.com/miaconnect1967"} title={""}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </td>

            <td>
              <LinkedinShareButton style={{ cursor: "pointer" }} url={"https://www.linkedin.com/"} title={""}>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Footer;
