export const faqsData = [
  {
    header: "1. How do I sign up for a training programme?",
    value: `<div>
                      i) Visit MIA website at
                      <a rel="noopener" href="https://www.mia.org.my">
                        https://www.mia.org.my
                      </a>
                      and click at "My Account" at the top right. Click "EVENTS" and continue with respective steps below.
                    </div>
                    <div>ii) Steps to create Profile for new users; otherwise login using your existing account.</div>
                    <h4>For New Profile</h4>
                    <img src="assets/Faqs/new.png" width="100%" />
                    <h4>For Existing Profile</h4>
                    <img src="assets/Faqs/register.png" />`
  },
  {
    header: "2. Can I email, fax or post my registration form?",
    value: "Only online registration will be accepted."
  },
  {
    header: "3. How do I check my registration status?",
    value: `<div>
                    Upon successful registration, you will receive a confirmation email within 24 hours in your profile. The
                      registration record is accessible in your corporate or individual inbox.
                    </div>
                    `
  },
  {
    header: "4. How and when do I make payment?",
    value: `
                  <div>i) Payment can be made by cash, credit card, cheque and online transfer. Payments in cheque to be made payable to <b>MALAYSIAN INSTITUTE OF ACCOUNTANTS</b>.</div>
                    <div>ii) <b>Individual Registration</b> - Full payment shall be made at the point of registration.</div>
                    <div>
                      iii) <b>Corporate Registration</b> - Full payment shall be made within thirty (30) days from the date of the Proforma Invoice or on the day of
                      the event, whichever earlier.
                    </div>
                    <div> Admittance may be denied upon failure to make payment as per the above requirement.</div>`
  },
  {
    header: "5. Can I pay online for a programme which I have registered?",
    value: ` <div>Yes. Log in to your account and select the registered event to proceed with online payment.</div>`
  },
  {
    header: "6. When will the Invoice be issued?",
    value: `<div>The invoice will be issued upon receipt of payment or after the event, whichever earlier.</div>`
  },
  {
    header: "7. Can I cancel my registration?",
    value: `<div>
              Should the participant decide to cancel his/her enrolment, a cancellation policy shall be applied as follows:
                    </div>
                    <div>a) Written cancellation received less than seven (7) days from the date of the event:</div>
                    <ol>
                      <li>A refund (less administrative charge of 20%) will be made</li>
                      <li>Unpaid registrations will also be liable for 20% administrative charges</li>
                    </ol>
                    <div>b) Written cancellation/ no show on the day of the programme:</div>
                    <ol>
                      <li>No refund will be entertained</li>
                      <li>Unpaid registrations will also be liable for full payment of the registration fee</li>
                      <li>Partial cancellation is not allowed</li>
                    </ol>
                    <div>c) For Complimentary talks/events, a penalty being the administrative and/or logistic cost of the event will be imposed as follows:</div>
                    <ol>
                      <li>RM100 for half day event</li>
                      <li>RM150 for full day event</li>
                    </ol>`
  },
  {
    header: "8. Can I replace the participants?",
    value: `<div>
                      No, it will be considered as a cancellation which will be subject to the Cancellation Policy. Substitutes for cancellation will be
                      treated as new registrations and full payment shall be made as per No.4 (ii) & (iii).
                    </div>`
  },
  {
    header: "9. Can I modify my organisation details for a registered events?",
    value: `<div>
              No, the organisation details cannot be modified. Therefore, please ensure to enter correct details during the corporate or individual profile creation and event registration.
            </div>`
  },
  {
    header: "10. Can I register for another event if there is an outstanding payment from previous events which has not been settled?",
    value: `<div>
    No, registration will be blocked due to unsettled outstanding for previous event. You are required to settle the outstanding amount in order to be able to proceed with registration for the next event.
                    </div>`
  },
  {
    header: "11. Can programmes be cancelled or postponed without prior notice?",
    value: `<div>
    Malaysian Institute of Accountants (MIA) reserves the right to change the speaker(s), date(s) and to cancel the programme should circumstances beyond its control arise. MIA shall not be responsible for any costs, damages or losses incurred by the participant due to the changes and/or cancellation. MIA also reserves the right to make alternative arrangements without prior notice should it be necessary to do so. Upon registering, you are deemed to have read and accepted the terms and conditions herein.
                    </div>`
  },
  {
    header: "12. When will I receive the Certificate of Attendance?",
    value: ` <div>Upon full attendance of the programme, participants will be issued an “e-Certificate of Attendance”.<b> e-Certificate will not be issued for partial
    attendance</b>. The copy of e-Certificate of Attendance is accessible in your corporate or individual inbox within 2 weeks after the event.
    </div>`
  },
  {
    header: "13. When will the CPE Hours be credited?",
    value: `<div>
    For MIA members, the CPE hours will be credited into the Membership System within 2 weeks after the event. MIA members will only be entitled to the CPE hours upon attending the entire duration of the programme. <b>CPE hours will not be accorded for partial attendance</b>.
                    </div>`
  },
  {
    header: "14. How do I contact MIA for further assistance on CPE training programme?",
    value: `
            <div>Contact us at:-</div>
            <div>Telephone: 03 - 2722 9000 Email: pd@mia.org.my</div>
            `
  },
  {
    header: "Claiming HRDF",
    value: `<div>HRDF SBL Claimable</div>
                      <div>
                        Most of the Training Programmes conducted by PD are eligible for HRDF claims under the Skim Bantuan Latihan (SBL) Scheme, subject to
                        prior application to HRDF by the employers/companies.
                      </div>
                      <h4>Application Procedures</h4>
                      <ul>
                        <li><b>Login</b> and <b>Launch eTRiS</b>.</li>
                        <li><b>Click</b> on <b>‘Application’</b>.</li>
                        <li>Select from menu <b> ‘Grant’ > ‘Apply Grant’</b>.</li>
                        <li>Select SBL from menu <b> ‘Scheme Code’ > ‘Apply’</b>.</li>
                        <li>Then fill up the form accordingly. Please use<b> “Institut Akauntan Malaysia” </b>as Training Provider’s name (MyCoID: <b>631967</b>).</li>
                        <li>For detailed instructions, kindly download User Guide (External) and go to Section 6 Grant Application with Scheme Codes: SBL.</li>
                      </ul>
                      <h4>Training Grant Claims</h4>
                      <div>
                        Training Grant claims will be paid upon submission of the claim form by the employers after the completion of the training programme. It
                        should be submitted by using the online claim form of which can be obtained from HRDF officers or the website. To avoid any delays in
                        processing claims, employers should ensure that the form is complete and correct. Relevant receipts must also be included. All copies of
                        receipts or supporting documents must be certified by the employer.
                      </div>
                      <ul>
                        <li><b>Login</b> to HDRF Portal and <b>launch eTRiS</b>.</li>
                        <li><b>Click</b> on <b>‘Application’</b> icon.</li>
                        <li>Select from menu, click on <b>‘Claim’</b>.</li>
                        <li>Choose either you want to <b>‘Submit Claims with Grants’</b> or <b>‘Submit Other Claims’</b>.</li>
                        <li>Please fill in the online form together with the supporting document, then click on <b>‘Submit’</b> button.</li>
                        <li>Click here to start claim.</li>
                      </ul>
        
                      <div>Note: Copies of the documents must be certified according to PSMB’s format.</div>
                      <div>
                        If you need assistance in claiming under the HRDF SBL Scheme, kindly contact us at: pd@mia.org.my or surf the Human Resource Development
                        Fund website at
                        <a rel="noopener" href="http://www.hrdf.com.my">
                          http://www.hrdf.com.my.
                        </a>
                      </div>
                      <div>i) Infokit SBL – Bahasa Malaysia</div>
                      <div>ii) Infokit SBL – English</div>`
  },
  {
    header: "SIDC Accreditation",
    value: `<h4>What is SIDC Accreditation?</h4>
                <ul>
                  <li>
                    Selected Training Programmes conducted by PD which are relevant to the capital market have been accredited by SIDC, the Securities
                    Industry Development Corporation.
                  </li>
                  <li>The CPE Tracker System will keep your participation records.</li>
                  <li>
                    PD will submit your participation details through CPE Tracker System within three (3) working days after the completion of the course.
                    You can check your accumulated CPE points at
                    <a rel="noopener" href="http://ers.seccom.com.my/cpepublic">
                      http://ers.seccom.com.my/cpepublic
                    </a>
                    .
                  </li>
                </ul>
                   `
  }
];
