import React from "react";

const CPE = () => (
  <div>
    <h4>PROGRAMME FEE</h4>
    <ul>
      <li>
        Fee is payable to <b>MALAYSIAN INSTITUTE OF ACCOUNTANTS</b>
      </li>
      <li>Depending on the event, the fee includes course materials and/or lunch and/or tea breaks.</li>
      <li>
        <b>Individual Registration</b> - Full payment shall be made at the point of online registration.
      </li>
      <li>
        <b>Corporate Registration</b> - Full payment shall be made within thirty (30) days from the date of the Proforma Invoice or on the day of the
        event, whichever earlier.
      </li>
      <li>Admittance may be denied upon failure to make full payment as per the above requirement.</li>
    </ul>

    <h4>CANCELLATION</h4>
    <p>Should the participant decide to cancel his/her enrolment, a cancellation policy shall be applied as follows:</p>
    <ul>
      <div> (a) Written cancellation received less than seven (7) days from the date of the event: </div>
      <ol>
        <li> Refund (less administrative charge of 20%) will be made.</li>
        <li> Unpaid registrations will also be liable for 20% administrative charges.</li>
      </ol>
      <div> (b) Written cancellation / no show on the day of the programme:</div>
      <ol>
        <li>No refund will be entertained.</li>
        <li> Unpaid registrations will also be liable for full payment of the registration fee.</li>
      </ol>
    </ul>
    <p>Substitutes for cancellation will be treated as a new registration and full payment shall be made as per the above requirement.</p>
    <p>
      The above is not applicable for MIA International Accountants Conference. Please refer to the terms & conditions at{" "}
      <a rel="noopener" href="http://miaconference.mia.org.my/">
        http://miaconference.mia.org.my/.
      </a>{" "}
    </p>
  </div>
);

export default CPE;
