module.exports = 
    [
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
        {
            img: 'https://member.mia.org.my/mia/mia_uat/mia/flyer/23062/Thumbnail',
            title: "Preparation of Group Accounts (Covering MFRS 3, Revised MFRS 127, 128 & 10-12)"
        },
    ];