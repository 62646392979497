export const find_in_object = (my_object, my_criteria) => {
    if(my_object){
        return my_object.filter(function(obj) {
            return Object.keys(my_criteria).every(function(c) {
                return obj[c] === my_criteria[c];
            });
        }); 
    }
};

export const compare = (a, b) => {
    const codNameA = a.codSortOrder;
    const codNameB = b.codSortOrder;
  
    let comparison = 0;
    if (codNameA > codNameB) {
      comparison = 1;
    } else if (codNameA < codNameB) {
      comparison = -1;
    }
    return comparison;
}
  