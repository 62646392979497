import types from "../../action_types";
import { faqsData } from "../../helpers/faqs";

const initialState = {
  data: faqsData,
  faqData: faqsData
};

export default function(state = initialState, action) {
  const { payload } = action || {};
  switch (action.type) {
    case types.SEARCH_FAQ:
      const { faqData } = state || {};
      const filterArray = faqData.filter(item => {
        const { header, value } = item || {};
        if (header.toUpperCase().includes(payload.toUpperCase()) || value.toUpperCase().includes(payload.toUpperCase())) {
          return item;
        }
      });
      return {
        ...state,
        data: filterArray
      };

    default:
      return state;
  }
}
