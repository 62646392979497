import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { bindActionCreators } from "redux";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import { searchFaq } from "../../actions/searchFaq";

import ExpansionPanels from "../../components/material-ui/ExpansionPanels";
import SearchInput from "../../components/SearchInput";

class FAQs extends React.Component {
  onSearchFaq = e => this.props.searchFaq(e.target.value);
  render() {
    const { data } = this.props.data || {};
    return (
      <div>
        <div className="push--bottom">
          <Hidden mdUp implementation="css">
            <SearchInput onChange={this.onSearchFaq} label="Search FAQ's" />
          </Hidden>
        </div>
        {data.map((item, index) => {
          const { header, value } = item || {};
          if (index === 0) {
            return <ExpansionPanels header={header}>{ReactHtmlParser(value)}</ExpansionPanels>;
          } else {
            return (
              <div className="push--top">
                <ExpansionPanels header={header}>{ReactHtmlParser(value)}</ExpansionPanels>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

FAQs.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchFaq
    },
    dispatch
  );

const mapStateToProps = state => {
  const { searchFaq: data } = state || {};
  return {
    data
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FAQs);

// export default FAQs;
