/* global document, window */
/* eslint no-underscore-dangle: 0 */
import types from "../../action_types";
import { isValidEmail, isValidContact } from "../../helpers/formValidation";

export const initialState = {
  salutation: "",
  name: "",
  nationality: "",
  dob: "",
  mobile: "",
  email: "",
  companyname: "",
  jobtitle: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  state: "",
  postcode: "",
  country: "",
  meal: "",
  eventID: "",
  fees: "1200",
  bankname: "",
  accountno: "",
  accountholder: "",
  validEmail: "",
  validContact: ""
};

export default function(state = initialState, action) {
  const { payload } = action || {};
  switch (action.type) {
    case types.SALUTATION_CHANGED:
      return {
        ...state,
        salutation: payload
      };

    case types.NAME_CHANGED:
      return {
        ...state,
        name: payload
      };

    case types.NATIONALITY_CHANGED:
      return {
        ...state,
        nationality: payload
      };

    case types.DOB_CHANGED:
      return {
        ...state,
        dob: payload
      };

    case types.MOBILE_NO_CHANGED:
      return {
        ...state,
        mobile: payload,
        validContact: isValidContact(payload)
      };

    case types.EMAIL_CHANGED:
      return {
        ...state,
        email: payload,
        validEmail: isValidEmail(payload)
      };

    case types.COMPANY_CHANGED:
      return {
        ...state,
        companyname: payload
      };

    case types.DESIGNATION_CHANGED:
      return {
        ...state,
        jobtitle: payload
      };

    case types.ADD1_CHANGED:
      return {
        ...state,
        address1: payload
      };

    case types.ADD2_CHANGED:
      return {
        ...state,
        address2: payload
      };

    case types.ADD3_CHANGED:
      return {
        ...state,
        address3: payload
      };

    case types.CITY_CHANGED:
      return {
        ...state,
        city: payload
      };

    case types.STATE_CHANGED:
      return {
        ...state,
        state: payload
      };

    case types.POST_CODE_CHANGED:
      return {
        ...state,
        postcode: payload
      };

    case types.COUNTRY_CHANGED:
      return {
        ...state,
        country: payload
      };

    case types.MEAL_PREF_CHANGED:
      return {
        ...state,
        meal: payload
      };

    case types.BANK_NAME_CHANGED:
      return {
        ...state,
        bankname: payload
      };

    case types.ACCOUNT_NAME_CHANGED:
      return {
        ...state,
        accountholder: payload
      };

    case types.ACCOUNT_NUMBER_CHANGED:
      return {
        ...state,
        accountno: payload
      };

    default:
      return state;
  }
}
