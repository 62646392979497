module.exports = {
  // api_base_url: "https://startroi.com/kirk/mia_uat",
  api_base_url: "https://mmis.mia.org.my/mia/mia_uat",
  merchantKey: "nf9tEILdIQ",
  merchantCode: "M15693",
  epayURL: "https://www.mobile88.com/ePayment/entry.asp",
  epaySignatureType: "SHA256",
  currency: "MYR",
  epayLang: "UTF-8"
};
