import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import CustomInput from "../../components/material-ui/CustomInput/CustomInput.jsx";
import Button from "../../components/material-ui/CustomButtons/Button.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardAvatar from "../../components/material-ui/Card/CardAvatar.jsx";
import CardBody from "../../components/material-ui/Card/CardBody.jsx";
import CardFooter from "../../components/material-ui/Card/CardFooter.jsx";
import { onAccountNameChange, onAccountNumberChange, onBankNameChange } from "../../actions/registrationActions";
import ReactHtmlParser from "react-html-parser";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "500",
    fontFamily: "'Montserrat', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "20px"
  }
};

class RefundDetails extends Component {
  render() {
    const { classes, registration } = this.props || {};
    const { bankname, accountno, accountholder } = registration || {};
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info">
              <div className={classes.cardTitleWhite}>Refund Details</div>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Bank Name<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="bankname"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onBankNameChange(e.target.value),
                      value: bankname
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Account Holder Name<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="accountholder"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onAccountNameChange(e.target.value),
                      value: accountholder
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={ReactHtmlParser(`<span>Account Number<sup style="color:red; font-size: 16px">*</sup></span>`)}
                    id="accountno"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => this.props.onAccountNumberChange(e.target.value),
                      value: accountno
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  const { registration } = state || {};
  return {
    registration
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onBankNameChange,
      onAccountNameChange,
      onAccountNumberChange
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RefundDetails));
