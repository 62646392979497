import React from "react";

const FOC = () => (
  <div>
    <p>For complimentary talks/events, a penalty being the administrative and/or logistic cost of the event will be imposed as follows:</p>
    <ul>
      <li>
        RM100 - <b>Half day</b> event
      </li>
      <li>
        RM150 - <b>Full day</b> event
      </li>
    </ul>

    <p>The said penalty shall be paid to MIA within 14 days after the event.</p>
    <p>
      The Terms & Conditions apply to all complimentary talks/events ("Events") that are organised by MIA, except for events organised by 3rd party:
    </p>
    <div>a) The Events organised by MIA is free of charge and open for MIA members only.</div>
    <div>b) The registration for the Events shall only be made online and manual registration/walk-in participants shall not be entertained.</div>
    <div>c) The online registration for the Events shall only be confirmed upon issuance of the Confirmation Notice by MIA.</div>
    <div>d) Each MIA member may only register and attend a maximum of two (2) Events in one (1) calendar year.</div>
    <div>e) Only two (2) participants from each organisation shall be allowed to register and attend the Events at any one time.</div>
    <div>f) MIA shall reserve the right to:</div>
    <ul>
      <li>not accept any registrations for the Events; and/or</li>
      <li>deny entry to the Events.</li>
    </ul>
    <p>The decision made by MIA with regard to the above rights shall be final.</p>
  </div>
);

export default FOC;
