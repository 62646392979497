import axios from 'axios';
import actionTypes from '../../action_types';
import config from '../../config';
import constants from '../../constants';

export function doRegistration(registerData) {
    console.log('data register', registerData)
    return dispatch =>
      axios
        .post('192.168.1.9:8080/kirk/mia_uat/pdpublic/register', registerData)
        .then(response => {
          console.log('respose on submit', response);
          if (response.status == 200) {
            if (response.data.message == constants.SUCCESS) {
              dispatch({
                type: actionTypes.PROJECT_REGISTRATION_SUCCESS,
                payload: response
              });
            } else {
              dispatch({
                type: actionTypes.PROJECT_REGISTRATION_FAILED,
                payload: response.data.reason
              });
            }
          } else {
            dispatch({
              type: actionTypes.PROJECT_REGISTRATION_FAILED,
              payload: constants.PROJECT_REGISTRATION_FAILED_MESSAGE
            });
          }
        })
        .catch(error => {
          console.log(error);
          dispatch({
            type: actionTypes.PROJECT_REGISTRATION_FAILED,
            payload: constants.PROJECT_REGISTRATION_FAILED_MESSAGE
          });
        });
}

export function onSalutationChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.SALUTATION_CHANGED,
        payload: value
      });
    };
}

export function onNameChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.NAME_CHANGED,
        payload: value
      });
    };
}

export function onNationalityChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.NATIONALITY_CHANGED,
        payload: value
      });
    };
}

export function onDobChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.DOB_CHANGED,
        payload: value
      });
    };
}

export function onMobileNoChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.MOBILE_NO_CHANGED,
        payload: value
      });
    };
}

export function onEmailChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.EMAIL_CHANGED,
        payload: value
      });
    };
}

export function onCompanyChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.COMPANY_CHANGED,
        payload: value
      });
    };
}

export function onDesignationChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.DESIGNATION_CHANGED,
        payload: value
      });
    };
}

// Address Details Actions
export function onAdd1Change(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.ADD1_CHANGED,
        payload: value
      });
    };
}

export function onAdd2Change(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.ADD2_CHANGED,
        payload: value
      });
    };
}

export function onAdd3Change(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.ADD3_CHANGED,
        payload: value
      });
    };
}

export function onCityChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.CITY_CHANGED,
        payload: value
      });
    };
}

export function onStateChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.STATE_CHANGED,
        payload: value
      });
    };
}

export function onPostCodeChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.POST_CODE_CHANGED,
        payload: value
      });
    };
}


export function onCountryChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.COUNTRY_CHANGED,
        payload: value
      });
    };
}

// Fee Details

export function onMealPrefChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.MEAL_PREF_CHANGED,
        payload: value
      });
    };
}

// Refund Details

export function onBankNameChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.BANK_NAME_CHANGED,
        payload: value
      });
    };
}

export function onAccountNameChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.ACCOUNT_NAME_CHANGED,
        payload: value
      });
    };
}

export function onAccountNumberChange(value) {
    return dispatch => {
      dispatch({
        type: actionTypes.ACCOUNT_NUMBER_CHANGED,
        payload: value
      });
    };
}

