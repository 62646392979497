import React, { Component } from "react";
import { Link } from "react-router-dom";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import ThumbUp from "@material-ui/icons/ThumbUp";
// core components
import GridItem from "../../components/material-ui/Grid/GridItem.jsx";
import GridContainer from "../../components/material-ui/Grid/GridContainer.jsx";
import Card from "../../components/material-ui/Card/Card.jsx";
import CardHeader from "../../components/material-ui/Card/CardHeader.jsx";
import CardIcon from "../../components/material-ui/Card/CardIcon.jsx";
import CardFooter from "../../components/material-ui/Card/CardFooter.jsx";
import CustomButtons from "../../components/material-ui/CustomButtons/Button";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";

class ChildDetails extends React.Component {
  state = {};

  incLike = eventID => {
    this.state.hasOwnProperty(eventID)
      ? this.setState({
          [eventID]: this.state[eventID] + 1
        })
      : this.setState({
          [eventID]: 1
        });
  };
  render() {
    const { classes, location, history } = this.props || {};
    const { state } = location || {};
    const { item } = state || {};
    const { childDetails } = item || {};
    return (
      <div>
        <a onClick={() => history.goBack()}>
          <span className="lnk">
            <KeyboardArrowLeft style={{ color: "black" }} />
            <span className="pos-rel" style={{ top: "-6px", color: "black" }}>
              Back
            </span>
          </span>
        </a>
        <GridContainer>
          {childDetails.map((item, index) => (
            <GridItem key={index} xs={12} sm={6} md={3}>
              <Card style={{ height: "450px" }}>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <img src={item.s3image} style={{ width: "100%", height: "150px", borderRadius: "3px" }} className="evt_img" />
                  </CardIcon>
                  <p className={`${classes.cardCategory} text--left`}>{item.title}</p>
                  {item.eventType === "ELE" ? (
                    <div />
                  ) : (
                    <div>
                      <div className={`${classes.cardCategory} text--left`}>{item.startDate && `${item.startDate} - ${item.endDate}`}</div>
                      <div style={{ color: "#999999" }} className={`text--left`}>
                        {item.venue}
                      </div>
                    </div>
                  )}
                </CardHeader>
                <CardFooter stats style={{ textAlign: "center", display: "block", position: "absolute", bottom: 0, left: 0, right: 0 }}>
                  <div className={classes.stats}>
                    <div>
                      <Link to={{ pathname: "/register", state: { item } }}>
                        <CustomButtons color="blueSecondaryColor">{item.btnName}</CustomButtons>
                      </Link>
                      {/* <CustomButtons color="blueSecondaryColor" onClick={() => this.incLike(item.id)}>
                        <ThumbUp style={{ height: "20px", width: "20px", top: 0 }} />
                        <span className="txt">{`(${this.state[item.id] || 0})`}</span>
                      </CustomButtons> */}
                      {/* <CustomButtons>
                                    <ThumbUp style={{height: '20px', width: '20px', top: 0}} />
                                </CustomButtons> */}
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(ChildDetails);
