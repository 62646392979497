import React, { Component } from "react";
import ReactDOM from "react-dom";
// import SpinnerComp from "../global/spinner";

export default class Map extends Component {
  // ======================
  // ADD LOCATIONS TO STATE
  // ======================
  state = {
    spin: false,
    locations: [
      { name: "MIA Head Office", location: { lat: 3.1142878, lng: 101.6660031 } },
      { name: "MIA Johor Regional Office", location: { lat: 1.46029, lng: 103.7594 } },
      { name: "MIA Northern Region Office", location: { lat: 5.42012, lng: 100.340762 } },
      { name: "MIA Sabah Regional Office", location: { lat: 5.961646, lng: 116.090486 } },
      { name: "MIA Sarawak Regional Office", location: { lat: 1.5558542, lng: 110.35183890000008 } }
    ]
  };

  componentDidMount() {
    this.loadMap(); // call loadMap function to load the google map
  }

  loadMap() {
    if (this.props && this.props.google) {
      // checks to make sure that props have been passed
      this.setState({
        spin: true
      });
      const { google } = this.props; // sets props equal to google
      const maps = google.maps; // sets maps to google maps props

      const mapRef = this.refs.map; // looks for HTML div ref 'map'. Returned in render below.
      const node = ReactDOM.findDOMNode(mapRef); // finds the 'map' div in the React DOM, names it node

      const mapConfig = Object.assign(
        {},
        {
          center: { lat: 1.544871893665478, lng: 106.38646109238289 }, // sets center of google map to NYC.
          zoom: 5, // sets zoom. Lower numbers are zoomed further out.
          mapTypeId: "roadmap" // optional main map layer. Terrain, satellite, hybrid or roadmap--if unspecified, defaults to roadmap.
        }
      );

      this.map = new maps.Map(node, mapConfig); // creates a new Google map on the specified node (ref='map') with the specified configuration set above.

      // ==================
      // ADD MARKERS TO MAP
      // ==================
      this.state.locations.forEach(location => {
        // iterate through locations saved in state
        const marker = new google.maps.Marker({
          // creates a new Google maps Marker object.
          position: { lat: location.location.lat, lng: location.location.lng }, // sets position of marker to specified location
          map: this.map, // sets markers to appear on the map we just created on line 35
          title: location.name // the title of the marker is set to the name of the location
        });
      });
      this.setState({
        spin: false
      });
    }
  }

  render() {
    const style = {
      // MUST specify dimensions of the Google map or it will not work. Also works best when style is specified inside the render function and created as an object
      //  width: '90vw', // 90vw basically means take up 90% of the width screen. px also works.
      height: "75vh" // 75vh similarly will take up roughly 75% of the height of the screen. px also works.
    };

    return (
      // in our return function you must return a div with ref='map' and style.
      <div ref="map" style={style}>
        <div id="spinner">{/* <SpinnerComp spin={this.state.spin} size={60} /> */}</div>
        loading map...
      </div>
    );
  }
}
