const actionTypes = {
  // Event Registration Action types
  PROJECT_REGISTRATION_SUCCESS: "PROJECT_REGISTRATION_SUCCESS",
  PROJECT_REGISTRATION_FAILED: "PROJECT_REGISTRATION_FAILED",
  SALUTATION_CHANGED: "SALUTATION_CHANGED",
  NAME_CHANGED: "NAME_CHANGED",
  NATIONALITY_CHANGED: "NATIONALITY_CHANGED",
  DOB_CHANGED: "DOB_CHANGED",
  MOBILE_NO_CHANGED: "MOBILE_NO_CHANGED",
  EMAIL_CHANGED: "EMAIL_CHANGED",
  COMPANY_CHANGED: "COMPANY_CHANGED",
  DESIGNATION_CHANGED: "DESIGNATION_CHANGED",
  ADD1_CHANGED: "ADD1_CHANGED",
  ADD2_CHANGED: "ADD2_CHANGED",
  ADD3_CHANGED: "ADD3_CHANGED",
  CITY_CHANGED: "CITY_CHANGED",
  STATE_CHANGED: "STATE_CHANGED",
  POST_CODE_CHANGED: "POST_CODE_CHANGED",
  COUNTRY_CHANGED: "COUNTRY_CHANGED",
  MEAL_PREF_CHANGED: "MEAL_PREF_CHANGED",
  BANK_NAME_CHANGED: "BANK_NAME_CHANGED",
  ACCOUNT_NAME_CHANGED: "ACCOUNT_NAME_CHANGED",
  ACCOUNT_NUMBER_CHANGED: "ACCOUNT_NUMBER_CHANGED",

  // Upcoming Traininngs
  TRAININGS_FETCHED_SUCCESS: "TRAININGS_FETCHED_SUCCESS",
  TRAININGS_FETCHED_ERROR: "TRAININGS_FETCHED_ERROR",
  SEARCH_BY_TITLE: "SEARCH_BY_TITLE",
  SEARCH_BY_VENUE: "SEARCH_BY_VENUE",
  SEARCH_BY_MONTH: "SEARCH_BY_MONTH",
  // Code Views
  CODE_VIEW_FETCHED_SUCCESS: "CODE_VIEW_FETCHED_SUCCESS",
  CODE_VIEW_FETCHED_ERROR: "CODE_VIEW_FETCHED_ERROR",

  // search
  SEARCH_FAQ: "SEARCH_FAQ"
};

export default actionTypes;
